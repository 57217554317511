//**************Docomo Digital**************

// Common code used by all instances.

//**************Change and refactor carefully****************

var all_common = all_common || {};

// region RegionManager

all_common.addRegions = function(regions) {
  if (all_common.rm == null) all_common.rm = new Marionette.RegionManager();
  return all_common.rm.addRegions(regions);
};

// endregion RegionManager

// region Data storage

all_common.data = function(index, value, buttonProps) {
  all_common.dataSet = all_common.dataSet || [];
  var res = null;
  if (index >= 0) {
    if (typeof value !== 'undefined') {
      all_common.dataSet[index] = all_common.dataSet[index] || {};
      $.extend(all_common.dataSet[index], value);
      if (typeof buttonProps !== 'undefined') {
        buttonProps['data-index'] = index;
        res = $('<button/>', buttonProps).prop('outerHTML');
      }
    }
    else {
      res = all_common.dataSet[index];
    }
  }
  return res;
};

//Predifined button props to make code more DRY

all_common.predifinedData = function(index, data, type) {
  var buttonProps = {};
  var dataSet = {};
  var title = '';
  dataSet[type] = data;
  switch (type) {
    case 'refund_sub':
      title = bg_globals.i18n.table.actions && bg_globals.i18n.table.actions.refund_subscription
          ? bg_globals.i18n.table.actions.refund_subscription
          : '';
      buttonProps = {
        'class': 'byg_tbut byg_tbut--refund',
        'title': title,
      };
      break;
    case 'refund_trx':
      title = bg_globals.i18n.table.actions && bg_globals.i18n.table.actions.refund_transaction
          ? bg_globals.i18n.table.actions.refund_transaction
          : '';
      buttonProps = {
        'class': 'byg_tbut byg_tbut--refund',
        'title': title,
      };
      break;
    case 'unsubscribe':
      title = bg_globals.i18n.table.actions && bg_globals.i18n.table.actions.unsubscribe_subscription
          ? bg_globals.i18n.table.actions.unsubscribe_subscription
          : '';
      buttonProps = {
        'class': 'byg_tbut byg_tbut--unsub',
        'title': title,
      };
      break;
    default:
      console.warn('No predifined Button Props found!');
      break;
  }
  return all_common.data(index, dataSet, buttonProps);
};

// endregion Data storage

// region Details two columns formatting

all_common.formatDetails = function($details, noEmptyClean) {
  // remove all empty rows
  if (!noEmptyClean) {
    $details.find('.byg_private__det--val').each(function() {
      if (!$.trim($(this).html())) {
        console.log($(this).closest('.row').find(".byg_private__det--prop").html() + " - is empty so hidden from details");
        $(this).closest('.row').remove();
      }
    });
  }

  // move half of rows to other column
  var $overflowContainer = $details.find('.js__det--overflowContainer');
  var $rows = $details.find('.byg_private__detailsWrap').find('.row');
  var breakpoint = Math.ceil($rows.length / 2);
  $rows.each(function(index) {
    if (index >= breakpoint) {
      var elem = $(this).detach();
      $overflowContainer.append(elem);
    }
  });

};

// endregion Details two columns formatting

// region Details long string ellipsing

all_common.ellipseDetails = function($details) {
  $details.find('.long').each(function() {
    var text = $(this).html();
    if (text.length > 33) {
      $(this).addClass("byg_private__det--long");
      var ellipsedText = text.substring(0, 30) + "...";
      $(this).html('<a class="popoverIcon" data-toggle="popover" data-content=""><i class="fa fa-eye" aria-hidden="true"></i></a>');
      $(this).find(".popoverIcon").attr("data-content", text).prepend(ellipsedText);
    }
  });
};

// endregion Details long values elipsing

// region DataTables utils
all_common.hideDatatable = function() { all_common.hide('.byg_private__dtWrap, .byg_private__formWrap '); };
all_common.showDatatable = function() {
  all_common.show('.byg_private__dtWrap, .byg_private__formWrap ');
  all_common.responiveRecalc();
};

all_common.reloadDatatables = function(e, datatables, callback) {
  if (e) e.preventDefault();
  all_common.hideMobileKeybord();
  datatables.ajax.reload(function() {
    all_common.responiveRecalc();
    if (typeof callback === 'function') callback();
  });
};

all_common.doSearch = function(e, datatables) {
  all_common.showPreloader('.byg_private__horizForm');

  all_common.showDatatable();
  all_common.reloadDatatables(e, datatables, function() {
    all_common.hidePreloader('.byg_private__horizForm');
    all_common.scrollToElement('.dataTables_wrapper');
  });
  $('.dataTables_wrapper').find('.byg_throbber').addClass('stickyPosition');
};

all_common.getDataColumn = function(row, meta, dataOrder, tableOrder, escapeHtml) {
  var out = 'N/A';
  var index = _.invert(tableOrder)[meta.col];
  if (typeof index === 'undefined') {
    console.warn('No record in Table Order array');
  }
  else {
    var dataIndex = dataOrder[index];
    if (typeof dataIndex === 'undefined') {
      console.warn('No record in Data Order array');
    }
    else {
      out = escapeHtml ? all_common.htmlEscapeEntities(row[dataIndex]) : row[dataIndex];
      if (typeof out === 'boolean') {
        if (out === true) {
          out = bg_globals.i18n.common.yes;
        }
        else {
          out = bg_globals.i18n.common.no;
        }
      }
    }
  }
  return out;
};

all_common.htmlEscapeDataColumnHandler = function(row, type, full, meta) {
  return all_common.htmlEscapeEntities(row[meta.col]);
};

all_common.responiveRecalc = function() {
  $('.dataTable').dataTable().api().responsive.recalc();
};

// endregion DataTables utils

// region Common utils

all_common.show = function(what) {
  $(what).removeClass('hidden');
};
all_common.hide = function(what) {
  $(what).addClass('hidden');
};

/* showPreloader */

all_common.showPreloader = function(where, noDecentHeight) {
  //old version
  //$(where).html(bg_globals.assets.preloader);
  $(where).addClass('rel');
  if (!noDecentHeight) $(where).addClass('decentHeight');
  var mask = '<div class="preloader-mask">';
  mask += bg_globals.assets.preloader_mask;
  mask += '</div>';
  $(where).append(mask);
};

/* hidePreloader */

// where: is the region element containing the preloader
// how  : removes the preloader div from the parent element provided

all_common.hidePreloader = function(where) {
  $(where).find('.preloader-mask').remove();
  $(where).removeClass('rel decentHeight');
};

/* smooth scrolling to element - useful to scroll forms to alerts

 where: css selector
 speed:animation duration in ms
 */
all_common.scrollToElement = function(where, speed) {
  try {
    speed = speed | 500;
    $('html, body').animate({
      scrollTop: $(where).offset().top,
    }, speed);
  } catch (e) {
  }
};

all_common.hideMobileKeybord = function() { // a bit of hacking to force closing on screen keybard on mobile devices
  $(':focus').each(function() {
    hideKeyboard($(this));
  });

  function hideKeyboard($element) {
    //READONLY and DISABLED leads to showing dropdown on autocomplete fields after item chosen
    //$element.attr('readonly', 'readonly'); // Force keyboard to hide on input field.
    //$element.attr('disabled', 'true'); // Force keyboard to hide on textarea field.
    setTimeout(function() {
      $element.blur();  //actually close the keyboard
      // Remove readonly attribute after keyboard is hidden.
      //$element.removeAttr('readonly');
      //$element.removeAttr('disabled');
    }, 100);
  }
};

// HTML sanitizer
all_common.htmlEscapeEntities = function(d) {
  return typeof d === 'string'
      ? d.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
      : d;
};

// endregion Common utils

// region Autocomplete throbber

all_common.autocompleteThrobber = function($input, state) {
  var $throbber = $input.siblings('.autocompleteThrobber');
  if ($throbber.length <= 0) {
    var $parent = $input.closest('.form-group');
    if (!$parent.length) $parent = $input.closest('div');
    $throbber = $parent
        .addClass('rel')
        .append('<div class=\'autocompleteThrobber\'>  <i class=\'fa\'></i></div>');
  }

  var $icon = $throbber.find('i');
  $icon.removeClass('fa-check fa-times fa-spinner fa-spin fa-ban');
  switch (state) {
    case 'reset':
      break;
    case 'busy':
      $icon.addClass('fa-spinner fa-spin');
      break;
    case 'success':
      $icon.addClass('fa-check');
      break;
    case 'fail':
      $icon.addClass('fa-times');
      break;
    case 'noResult':
      $icon.addClass('fa-ban');
      break;
  }

};
// endregion Autocmplete throbber

/** Lock a region to prevent user interaction
 * lock = true disables all inputs
 * lock = false enables all inputs
 * **/
all_common.lockRegion = function(selector, lock) {
  $(selector).find(':input').prop('disabled', lock);
};
/* cookie banner manager. */

$(document).ready(function() {

    // Instances that don't have bg_globals.featureEnabled section will have always cookie banner enabled
    if (!bg_globals.featureEnabled || bg_globals.featureEnabled.cookieBanner) {

        // bg_utils.log('[cookies] document ready, iframe:', bg_globals.iframe_login,
        //     'area_class:', bg_globals.area_class,
        //     'cookiesAccepted:', $.cookie('cookiesAccepted'));

        if (typeof bg_globals.iframe_login === 'undefined' &&
            bg_globals.area_class === 'byg_private'     &&
            $.cookie('pwdExpiring') === 'true') {
            $('#pwdExpirationDate').text($.cookie('pwdExpirationDate'));
            bg_utils.show('.byg_pwdExpiring-banner');
        }

        /* do nothing if:
         * - in iframe-login
         * - in private area
         * - if cookies is already set */

        if (typeof bg_globals.iframe_login !== 'undefined' ||
            bg_globals.area_class === 'byg_private'        ||
            $.cookie('cookiesAccepted') === 'true'
        ) {
            return;
        }

        var acceptCookie = function() {
            if (typeof $.cookie('cookiesAccepted') === 'undefined') {
                $.cookie('cookiesAccepted', true, { 'expires': 365 }); // 365 days
                bg_utils.hide('.byg_cookie-banner');
                bg_utils.log('[cookies] cookies accepted');
            }
            else {
                bg_utils.log('[cookies] cookies already present, nothing to do');
            }
        };

        /* bind event on any element (except more info) */

        $('a:not(".byg_cookie-banner__more-info"), button, input').click(function(e) {
            acceptCookie();
        });

        /* bind event on 'ok' button. Don't let event bubble up to 'document' */

        $('.byg_cookie-banner a.byg_cookie-banner__ok').click(function(e) {
            e.stopPropagation();
            e.preventDefault();
            acceptCookie();
        });

        /* bind event on 'more info' button. Don't let event bubble up to 'document' */

        $('.byg_cookie-banner a.byg_cookie-banner__more-info').click(function(e) {
            e.stopPropagation();
        });

        bg_utils.show('.byg_cookie-banner');
    }
});

  // http://stackoverflow.com/questions/9160123/no-transport-error-w-jquery-ajax-call-in-ie
$.support.cors = true;

function sameOrigin(url) {
    // test that a given url is a same-origin URL
    // url could be relative or scheme relative or absolute
    var host = window.location.host; // host + port
    var protocol = window.location.protocol;
    var sr_origin = '//' + host;
    var origin = protocol + sr_origin;
    // Allow absolute or scheme relative URLs to same origin
    return (url == origin || url.slice(0, origin.length + 1) == origin + '/') ||
        (url == sr_origin || url.slice(0, sr_origin.length + 1) == sr_origin + '/') ||
        // or any other URL that isn't scheme relative or absolute i.e relative.
        !(/^(\/\/|http:|https:).*/.test(url));
}

$.ajaxSetup({
  'beforeSend': function (xhr, settings) {
    if (!/^(GET|HEAD|OPTIONS|TRACE)$/i.test(settings.type) && sameOrigin(settings.url)) {
      xhr.setRequestHeader(bg_globals.csrf_token_key, bg_globals.csrf_token_value);
    }
  },
  'cache': false,
  'jsonp': false,
  // http://stackoverflow.com/questions/2540827/jquery-parameter-serialization-without-the-bracket-mess
  'traditional': true
});

var bg_consts = bg_consts || {};

bg_consts = {
  'API': {
    'PASSPORT_LOGIN'                                        : bg_globals.base_url + 'lidentify',
    'RESET_PASSWORD'                                        : bg_globals.base_url + 'resetPassword.json',
    'RESET_PASSWORD_BY_PHONE'                               : bg_globals.base_url + 'resetPasswordPhone.json',
    'RESET_PASSWORD_CONFIRM'                                : bg_globals.base_url + 'resetPasswordConfirm.json',
    'RESET_PASSWORD_BY_PHONE_CONFIRM'                       : bg_globals.base_url + 'resetPasswordPhoneConfirm.json',
    'TRACK_PURCHASE_LIST'                                   : bg_globals.base_url + 'trackPurchase.json',
    'IS_USER_SUSPENDED'                                     : bg_globals.base_url + 'blacklistSuspension/isUserSuspended.json',
    'CUSTOMER_IS_SUSPENDED':                                  bg_globals.base_url + 'customer/isSuspended.json',
    // CUSTOMER
    'CUSTOMER_OTPLOGIN_SENDOTP'                             : bg_globals.base_url + 'sendOtpLogin.json',
    'CUSTOMER_OTPLOGIN_VALIDATEOTP'                         : bg_globals.base_url + 'auth/otp-login',
    'CUSTOMER_REGISTRATION'                                 : bg_globals.base_url + 'customerRegistration.json',
    'CUSTOMER_REGISTRATION_OTP_CONFIRM'                     : bg_globals.base_url + 'customerRegistrationOTPConfirm.json',
    'CUSTOMER_REGISTRATION_OTP_RESEND'                      : bg_globals.base_url + 'customerRegistrationOTPResend.json',
    'CUSTOMER_REGISTRATION_WITH_PHONE'                      : bg_globals.base_url + 'customerRegistrationWithPhone.json',
    'CUSTOMER_REGISTRATION_WITH_PHONE_OTP_CONFIRM'          : bg_globals.base_url + 'customerRegistrationWithPhoneOTPConfirm.json',
    'CUSTOMER_REGISTRATION_RESEND'                          : bg_globals.base_url + 'customerRegistrationResend.json',
    'CUSTOMER_GET_TRANSACTIONS'                             : bg_globals.base_url + 'customer/getTransactionsStatus.json',
    'CUSTOMER_GET_TRANSACTION_BMONITOR_DATA'                : bg_globals.base_url + 'customer/getTransactionsBMonitorData.json',
    'CUSTOMER_GET_TRANSACTION_DETAIL'                       : bg_globals.base_url + 'customer/getTransactionDetails.json',
    'CUSTOMER_GET_SUBSCRIPTIONS'                            : bg_globals.base_url + 'customer/getSubscriptions.json',
    'CUSTOMER_GET_SUBSCRIPTIONS_BMONITOR_DATA'              : bg_globals.base_url + 'customer/getSubscriptionsBMonitorData.json',
    'CUSTOMER_GET_SUBSCRIPTION_DETAILS'                     : bg_globals.base_url + 'customer/getSubscriptionDetails.json',
    'CUSTOMER_GET_DETAILS'                                  : bg_globals.base_url + 'customer/getCustomerDetails.json',
    'CUSTOMER_GET_LIMITS'                                   : bg_globals.base_url + 'customer/getCustomerLimits.json',
    'CUSTOMER_GET_SUSPENSION_HISTORY'                       : bg_globals.base_url + 'blacklistSuspension/getCustomerSuspensionHistory.json',
    'CUSTOMER_SUSPEND_USER'                                 : bg_globals.base_url + 'blacklistSuspension/suspendUser.json',
    'CUSTOMER_SESSION_SUSPEND_USER':                          bg_globals.base_url + 'customer/sessionSuspendUser.json',
    'CUSTOMER_UPDATE_DETAILS'                               : bg_globals.base_url + 'customer/updateCustomerDetails.json',
    //'CUSTOMER_DELETE_ACCOUNT'                               : bg_globals.base_url + 'customer/disableAccount.json',
    'CUSTOMER_SEARCH_PRODUCT'                               : bg_globals.base_url + 'customer/searchProduct.json',
    'CUSTOMER_UNSUBSCRIBE_USER'                             : bg_globals.base_url + 'customer/unsubscribeUser.json',
    'CUSTOMER_UNSUBSCRIBE_USER_BY_SUB_ID'                   : bg_globals.base_url + 'customer/unsubscribeUserBySubId.json',
    // MERCHANT
    'MERCHANT_REGISTRATION'                                 : bg_globals.base_url + 'merchantRegistration.json',
    'MERCHANT_REGISTRATION_RESEND'                          : bg_globals.base_url + 'merchantRegistrationResend.json',
    'MERCHANT_GET_COMBINATIONS'                             : bg_globals.base_url + 'customPE/searchCombinationId.json',
    'MERCHANT_GET_COMBINATIONS_MT'                          : bg_globals.base_url + 'customPE/mtSearchCombinationId.json',
    'MERCHANT_CREATE_COMBINATION'                           : bg_globals.base_url + 'customPE/createCombinationId.json',
    'MERCHANT_CREATE_COMBINATION_MT'                        : bg_globals.base_url + 'customPE/mtCreateCombinationId.json',
    'MERCHANT_DELETE_COMBINATION'                           : bg_globals.base_url + 'customPE/deleteCombinationId.json',
    'MERCHANT_DELETE_COMBINATION_MT'                        : bg_globals.base_url + 'customPE/mtDeleteCombinationId.json',
	'MERCHANT_PUBLISH_COMBINATION'                          : bg_globals.base_url + 'customPE/changeVisibility.json',
    'MERCHANT_CLONE_COMBINATION'                            : bg_globals.base_url + 'customPE/cloneCombination.json',
    'MERCHANT_CLONE_COMBINATION_MT'                         : bg_globals.base_url + 'customPE/mtCloneCombination.json',
    'MERCHANT_CREATE_STYLE'                                 : bg_globals.base_url + 'customPE/createStyleId.json',
    'MERCHANT_DELETE_STYLE'                                 : bg_globals.base_url + 'customPE/deleteStyleId.json',
    'MERCHANT_ASSOCIATE_STYLE'                              : bg_globals.base_url + 'customPE/updateStyleId.json',
    'MERCHANT_GET_SITES_VIEW'                               : bg_globals.base_url + 'merchant/getSitesView.json',
    'MERCHANT_GET_STYLES'                                   : bg_globals.base_url + 'customPE/searchStyleId.json',
    'MERCHANT_GET_STYLES_MT'                                : bg_globals.base_url + 'customPE/mtSearchStyleId.json',
    'MERCHANT_GET_STYLEABLE_SITES'                          : bg_globals.base_url + 'customPE/getStyleableSites.json',
    'MERCHANT_GET_TRANSACTIONS'                             : bg_globals.base_url + 'merchant/getTransactionsStatus.json',
    'MERCHANT_GET_SUBSCRIPTIONS'                            : bg_globals.base_url + 'merchant/getSubscriptions.json',
    'MERCHANT_GET_SUBSCRIPTIONS_BY_STATUS'                  : bg_globals.base_url + 'merchant/getSubscriptionsByStatus.json',
    'MERCHANT_GET_SUBSCRIPTION_DETAILS'                     : bg_globals.base_url + 'merchant/getSubscriptionDetails.json',
    'MERCHANT_GET_SUBSCRIPTION_TRANSACTIONS'                : bg_globals.base_url + 'merchant/getSubscriptionTransactions.json',
    'MERCHANT_GET_SUBSCRIPTIONS_BMONITOR_DATA'              : bg_globals.base_url + 'merchant/getSubscriptionsBMonitorData.json',
    'MERCHANT_GET_TRANSACTION_DETAIL'                       : bg_globals.base_url + 'merchant/getTransactionDetails.json',
    'MERCHANT_GET_TRANSACTION_BMONITOR_DATA'                : bg_globals.base_url + 'merchant/getTransactionsBMonitorData.json',
    'MERCHANT_TRANSACTIONS_B_MONITOR_PAYOUT_DATA'           : bg_globals.base_url + 'merchant/getTransactionsBMonitorPayoutData.json',
    'MERCHANT_GET_SUBSCRIPTION_TRANSACTIONS_FOR_REFUND'     : bg_globals.base_url + 'merchant/getSubscriptionTransactionsForRefund.json',
    'MERCHANT_GET_HOME_BMONITOR_DATA'                       : bg_globals.base_url + 'merchant/getHomeBMonitorData.json',
    'MERCHANT_REFUND_TRANSACTIONS'                          : bg_globals.base_url + 'merchant/refundTransactions.json',
    'MERCHANT_UNSUBSCRIBE_USER'                             : bg_globals.base_url + 'merchant/unsubscribeUser.json',
    'MERCHANT_UNSUBSCRIBE_USER_BY_SUB_ID'                   : bg_globals.base_url + 'merchant/unsubscribeUserBySubId.json',
    'MERCHANT_GET_DETAILS'                                  : bg_globals.base_url + 'merchant/getMerchantDetails.json',
    'MERCHANT_GET_SITES'                                    : bg_globals.base_url + 'merchant/getSites.json',
    'MERCHANT_CHANGE_PASSWORD'                              : bg_globals.base_url + 'merchant/changePassword.json',

    'SEND_CONTACT_FORM_MERCHANT'                            : bg_globals.base_url + 'sendContactFormMerchant.json',
    'SEND_CONTACT_FORM_CUSTOMER'                            : bg_globals.base_url + 'sendContactFormCustomer.json',
    //CUSTOMER CARE
    'CUSTOMER_CARE_GET_TRANSACTIONS'                        : bg_globals.base_url + 'telco/' + 'customerCare/getTransactions.json',
    'CUSTOMER_CARE_GET_TRANSACTION_DETAILS'                 : bg_globals.base_url + 'telco/' + 'customerCare/getTransactionDetails.json',
    'CUSTOMER_CARE_GET_SUBSCRIPTIONS'                       : bg_globals.base_url + 'telco/' + 'customerCare/getSubscriptions.json',
    'CUSTOMER_CARE_GET_SUBSCRIPTION_DETAILS'                : bg_globals.base_url + 'telco/' + 'customerCare/getSubscriptionDetails.json',
    'CUSTOMER_CARE_GET_SUBSCRIPTION_TRANSACTIONS'           : bg_globals.base_url + 'telco/' + 'customerCare/getSubscriptionTransactions.json',
    'CUSTOMER_CARE_GET_SUBSCRIPTION_TRANSACTIONS_FOR_REFUND': bg_globals.base_url + 'telco/' + 'customerCare/getSubscriptionTransactionsForRefund.json',
    'CUSTOMER_CARE_REFUND_TRANSACTIONS'                     : bg_globals.base_url + 'telco/' + 'customerCare/refundTransactions.json',
    'CUSTOMER_CARE_UNSUBSCRIBE_USER'                        : bg_globals.base_url + 'telco/' + 'customerCare/unsubscribeUser.json',
    'CUSTOMER_CARE_UNSUBSCRIBE_USER_BY_SUB_ID'              : bg_globals.base_url + 'telco/' + 'customerCare/unsubscribeUserBySubId.json',
    'CUSTOMER_CARE_GET_CUSTOMER_DETAILS'                    : bg_globals.base_url + 'telco/' + 'customerCare/customer-info.json',
    'CUSTOMER_CARE_GET_CUSTOMER_LIMITS'                     : bg_globals.base_url + 'telco/' + 'customerCare/phone-limits.json',
    'CUSTOMER_CARE_GET_CUSTOMER_NEXT_MONTHLY_LIMIT':          bg_globals.base_url + 'telco/' + 'customerCare/phone-next-m-limit.json',
    'CUSTOMER_CARE_IS_USER_SUSPENDED'                       : bg_globals.base_url + 'telco/' + 'blacklistSuspension/isUserSuspended.json',
    'CUSTOMER_CARE_GET_SUSPENSION_HISTORY'                  : bg_globals.base_url + 'telco/' + 'blacklistSuspension/getSuspensionHistory.json',
    'CUSTOMER_CARE_SUSPEND_USER'                            : bg_globals.base_url + 'telco/' + 'blacklistSuspension/suspendUser.json',
    'CUSTOMER_CARE_GET_BLACKLISTED_SITES'                   : bg_globals.base_url + 'telco/' + 'blacklistSuspension/getBlacklistedSites.json',
    'CUSTOMER_CARE_TOGGLE_BLACKLIST'                        : bg_globals.base_url + 'telco/' + 'blacklistSuspension/toggleBlacklist.json',
    'CUSTOMER_CARE_TOGGLE_BLACKLIST_MULTI'                  : bg_globals.base_url + 'telco/' + 'blacklistSuspension/toggleBlacklistMulti.json',
    //BACKOFFICE
    'BACKOFFICE_SEARCH_SITE'                                : bg_globals.base_url + 'telco/' + 'backoffice/getSites.json',
    'BACKOFFICE_SEARCH_BY_VALUE'                            : bg_globals.base_url + 'telco/' + 'backoffice/searchValue.json',
    'BACKOFFICE_GET_SITE'                                   : bg_globals.base_url + 'telco/' + 'backoffice/getSite.json',
    'BACKOFFICE_INSERT_SITE'                                : bg_globals.base_url + 'telco/' + 'backoffice/insertSite.json',
    'BACKOFFICE_UPDATE_SITE'                                : bg_globals.base_url + 'telco/' + 'backoffice/editSite.json',
    'BACKOFFICE_MANAGE_SITE'                                : bg_globals.base_url + 'telco/' + 'backoffice/manageSite.json',
    'BACKOFFICE_GET_MERCHANT'                               : bg_globals.base_url + 'telco/' + 'backoffice/getMerchantProfile.json',
    'BACKOFFICE_UPDATE_MERCHANT'                            : bg_globals.base_url + 'telco/' + 'backoffice/editMerchant.json',
    'BACKOFFICE_GET_COMBINATIONS'                           : bg_globals.base_url + 'telco/' + 'customPE/adminSearchCombinationId.json',
    'BACKOFFICE_GET_COMBINATIONS_MT'                        : bg_globals.base_url + 'telco/' + 'customPE/mtAdminSearchCombinationId.json',
    'BACKOFFICE_GET_STYLES'                                 : bg_globals.base_url + 'telco/' + 'backoffice/searchStyleByCombinationId.json',
    'BACKOFFICE_GET_STYLE_BY_COMBINATION_ID'                : bg_globals.base_url + 'telco/' + 'customPE/mtSearchStyleByCombinationId.json',
    'BACKOFFICE_UPDATE_COMBINATION'                         : bg_globals.base_url + 'telco/' + 'customPE/admin/setCombinationIdStatus.json',
    'PROFILE_CHANGE_PASSWORD'                               : bg_globals.base_url + 'profile/changePassword.json',
  },
  'PAGES': {
    'MERCHANT_UNCONFIRMED': bg_globals.base_url + 'merchant-unconfirmed'
  },
  'SIGNUP_FORMS': {
    'PASSWORD_REGEX':/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@!%*?&]{10,64}/,
    'PASSWORD_MIN_LEN': 8,
    'COMPANY_MIN_LEN' : 3,
    'VAT_MIN_LEN': 8
  },
  'DEBUG': 'false',
  'OPERATIONS': {
    'REFUND': 'refund_enabled',
    'UNSUBSCRIBE': 'deactivate_sub',
    'EDIT_SITE': 'site_edit'
  }
};

var bg_datatables = bg_datatables || {};

/* init
 * initialize datatables. Parameters:
 *
 * where: jQuery selector where to render dataTables
 * url: url for ajax call
 * dataFnc: callback function for data manipulation (before send)
 * drawFnc: callback function fired once table has been drawn
 * columnDefs: column rendering
 * moreParams: additional params
 * moreAjaxParams: additional params for ajax
 */

bg_datatables.init = function(where, url, dataFnc, drawFnc, columnDefs, initialOrder, moreParams, moreAjaxParams) {

  var params = {
    'serverSide': true,
    'searching': false,
    'processing': true,   // show preloader
    'info': false,
    'pagingType': 'simple',
    'ajax': {
      'url': url,
      'method': 'POST',
      'error': function(xhr, status, err) {
        bg_utils.apiErrorManager(xhr, status, err);
        /* other custom errors here */
      },
      'data': dataFnc
      /*'dataSrc': function(json) { <--- use this to tweak data received from server
        for (var i=0; i<json.data.length; i++) {
          json.data[i].splice(0, 1);
        }
        bg_utils.log(json);
        return json.data;
      }
       /*  Example cutting arr length to column object key length
       var moreAjaxParams ={
     'dataSrc' : function(json){
       var nuData =[];
       var keysNum = _.keys(mt.columns).length;
       _.map(json.data,function(arr){
         return arr.splice(keysNum);
       });
       console.log(json);
      return json.data;
     }
   };


      */
    },

    'drawCallback': drawFnc,

    'order': initialOrder,

    /* move pagination and results-per-page inside the proper div when
     * everything is done */

    /*'initComplete': function() {
      $('.dataTables_length').appendTo('.bg-pagination__result-per-page');
      $('.dataTables_paginate').appendTo('.bg-pagination__paginator');
    },*/

    'language': {
      'emptyTable': bg_globals.i18n.table.empty,
      'processing': bg_globals.assets.preloader,
      'lengthMenu': bg_globals.i18n.table.length_menu,
      'paginate': {
        'previous': '&lt;',
        'next': '&gt;'
      }
    },

    'columnDefs': columnDefs
  };

  params = _.extend(params, moreParams);
  if (typeof moreAjaxParams !== 'undefined') {
    params.ajax = _.extend(params.ajax, moreAjaxParams);
  }

  return $(where).DataTable(params);
};


/* initManual
 * initialize datatables by passing manual data to it. */

bg_datatables.initManual = function(where, data, columnDefs, initialOrder, searching) {

  var params = {
    'serverSide':   false,
    'searching':    searching || false,
    'responsive':   true,
    'info':         false,
    'data':         data,
    'columnDefs':   columnDefs,
    'order':        initialOrder,
    'language':     {
      'emptyTable': bg_globals.i18n.table.empty,
      'processing': bg_globals.assets.preloader,
      'lengthMenu': bg_globals.i18n.table.length_menu,
      'paginate':   {
        'previous': '&lt;',
        'next':     '&gt;'
      }
    }
  };

  return $(where).DataTable(params);
};

$(document).ready(function() {
  bg_utils.preloadImages(bg_globals.assets.preloader_src);

  /* click on login button */

  $('.navbar .naviList__login, .naviList__login--mobile, .navbar .icon-user, .naviList__username, .navbar-nav__username').on('click',function(ev) {
    ev.stopImmediatePropagation();
    ev.preventDefault();

    if (bg_globals.logged === true) {
      var myAccountPath      = $('.navi-myaccount__link').attr('href');
      var myAccountOrDefault = (_.isEmpty(myAccountPath)) ? bg_globals.welcome_page : myAccountPath;
      window.location.replace(myAccountOrDefault);
    }
    else {
      $('.navbar-collapse.collapse.in').collapse('hide');
      bg_utils.show('.page__loginWrap');
      $('.ie9 .page__loginWrap').css('width', '300px'); // ie9 fix: force iframe redraw

      $('body').off().one('click', function() {
        bg_utils.hide('.page__loginWrap');
      });
      $('#loginFormNameF').focus();
    }
  });

  /* Entry point (user clicks on "login" link) */
  $(".passport-login-btn").on('click',function (e) {
      e.preventDefault(e);

      /*
      if (urlParams['ltype'] !== undefined && urlParams['ltype'] === 'popup') {
          showRegularLogin();
      }
      else
      */
          doPassportLogin();

  });
  //prevent event bubbling which would close the popup
  $('.loginForm__cont').click(function(e) {
    e.stopPropagation();
  });

  
  //swap selfcare - merchant login forms
  var jMerchantForm=$(".loginUname");
  var jMerchantFormTitle=$(".loginUname__title");

  var jSelfcareForm=$(".loginPassport");
  var jSelfcareFormTitle=$(".loginPassport__title");
  //var jSelfcareForm=$(".loginOtp");
  //var jSelfcareFormTitle=$(".loginOtp__title");
  var jSelfcareMsisdn=$(".loginCard__msisdn");
  //var jSelfcareOtp=$(".loginCard__otp");
  var uidStored="";


  $(".loginForm_asMerchantLink .bygForm__link").click(function(){
   bg_utils.showEl(jMerchantForm);
   bg_utils.showEl(jMerchantFormTitle);
   bg_utils.hideEl(jSelfcareForm);
   bg_utils.hideEl(jSelfcareFormTitle);
  });

  $(".loginForm_asSelfcareLink .bygForm__link").click(function(){
    bg_utils.hideEl(jMerchantForm);
    bg_utils.hideEl(jMerchantFormTitle);
    bg_utils.showEl(jSelfcareForm);
    bg_utils.showEl(jSelfcareFormTitle);
    showPassportLogin();
  });


function doPassportLogin() {
        $.ajax({
            'url':      bg_consts.API.PASSPORT_LOGIN,
            'type':     'GET',
            'dataType': 'json', // expected type from server

            beforeSend: function (jqXHR, settings) {
                bg_utils.show('.byg_formMask.passport');
            },
            'success':  function (data, status, xhr) {
                bg_utils.log('recognition request ok!');
                bg_utils.log('data: ', data);

                if (data.status === "OK" && data.redirectUrl !== 'null') {
                    window.top.location.href = data.redirectUrl;
                }
                else showPassportErrorLogin();
            },
            'error':    function (xhr, status, error) {
                bg_utils.log('recognition error!');
                bg_utils.log('status: ', status);
                bg_utils.log('error: ', error);
                bg_utils.log('xhr: ', xhr);

                showPassportErrorLogin();

            },
            'complete': function () {
                bg_utils.hide('.byg_formMask.passport');
            }
        });
    }



  /* submit login - merchant */
  $('.loginUname').submit(function(e) {
    e.preventDefault();
    $('.loginForm_err.bygForm__error').html('&nbsp;');

    //bg_utils.showPreloader('.loginForm_err.bygForm__error');
    bg_utils.show('.byg_formMask');

    // Check login username format
    var uid          = $('#loginFormNameF').val();
    var processedUid = bg_utils.uidProcessor(uid);



    $.ajax({
      'url':      bg_globals.custom_login_url == null ? bg_globals.login_url : bg_globals.custom_login_url,
      'type':     'POST',
      'dataType': 'json', // expected type from server
      'data':     {
        'username': processedUid,
        'password': $('#loginFormPasswordF').val(),
        'timezone': new Date().getTimezoneOffset()
      },
      'success':  function(data, status, xhr) {
        bg_utils.log('success!');
        bg_utils.log('status: ', status);
        bg_utils.log('data: ', data);
        bg_utils.log('data (str): ', JSON.stringify(data));
        window.top.location.href = bg_globals.base_url + data.redirectUrl; // redirect parent (we are inside an iframe now)
      },
      'error':    function(xhr, status, error) {
        bg_utils.log('error!');
        bg_utils.log('status: ', status);
        bg_utils.log('error: ', error);
        bg_utils.log('xhr: ', xhr);

        if (xhr.status === 902) {
          window.top.location.href = bg_consts.PAGES.MERCHANT_UNCONFIRMED;
        }
        else if (xhr.status === 401) {
          window.top.location.reload(true);
        }
        else {
          if (_.has(xhr.responseJSON, 'errorMsg')) {
            $('.loginForm_err.bygForm__error').html(xhr.responseJSON.errorMsg);
          }
          else {
            $('.loginForm_err.bygForm__error').html(bg_globals.i18n.errors.generic_error);
          }
        }
      },
      'complete': function() {
        bg_utils.hide('.byg_formMask');
      }
    });
  });

  function showPassportErrorLogin() {
      bg_utils.hide('.byg_formMask.passport'); // Preloader while calling identify);
      bg_utils.hide($('#loginPassport-info'));
      bg_utils.show($('#loginPassport-error'));
  }

  function showPassportLogin() {
        bg_utils.hide($('#loginPassport-error'));
        bg_utils.hide('.byg_formMask.passport'); // Preloader while calling identify);
        bg_utils.show($('#loginPassport-info'));
    }

/* OTP LOGIN
  function showOtpForm(){
    bg_utils.hideEl(jSelfcareMsisdn);
    bg_utils.showEl(jSelfcareOtp);
  }

  $(".selfcare_loginForm_otp .loginForm_changeNumberLink").click(
      function () {
        bg_utils.hideEl(jSelfcareOtp);
        bg_utils.showEl(jSelfcareMsisdn);
        $('#loginFormMsisdnF').val("");
        $('#loginFormOtpF').val("");
        $('span').html("");
        $('.selfcare_loginForm__countryCode').html("+61");
        bg_utils.captchaReset();
        uidStored="";
      }
  );

  /* submit first step login - selfcare msisdn
    $('.loginCard__msisdn .loginFormSubmitBut').click(function(e) {
      e.preventDefault();

      // $('.loginForm_err.bygForm__error').html('&nbsp;');

      var uid = $('#loginFormMsisdnF').val();



      //check is empty
      var isEmptyMsisdn = false;
      var isEmptyCaptcha = false
      var isNumber = false;
      var captchaValue = grecaptcha.getResponse();
      uid = bg_utils.removeFirstZeroByMsisdn(uid);
      isEmptyMsisdn = bg_utils.isEmptyOrNull(uid,'#loginFormMsisdnF-error',bg_globals.i18n.form.errors.missing_field);
      isEmptyCaptcha = bg_utils.isEmptyOrNull(captchaValue,'#loginFormCaptcha-error',bg_globals.i18n.form.errors.captcha);
      isNumber = bg_utils.isOnlyNumber(uid);
      if (isEmptyMsisdn !== true && isNumber !== true ){
        $('#loginFormMsisdnF-error').html(bg_globals.i18n.form.errors.phone_merchant);
        return false;
      }
      else if (isEmptyMsisdn !== true && isNumber === true ){
        $('#loginFormMsisdnF-error').html("");
      }

      if(isEmptyMsisdn === true || isEmptyCaptcha === true ) {
        return false;
      }

      var processedUid = "+61"+ uid;

      bg_utils.show('.byg_formMask');

      $.ajax({
        'url':      bg_consts.API.CUSTOMER_OTPLOGIN_SENDOTP,
        'type':     'POST',
        'dataType': 'json', // expected type from server
        'data':     {
          'msisdn': processedUid,
          'captchaValue':captchaValue,
          'timezone': new Date().getTimezoneOffset()
        },
        'success':  function(data, status, xhr) {
          bg_utils.log('success!');
          bg_utils.log('status: ', status);
          bg_utils.log('data: ', data);
          bg_utils.log('data (str): ', JSON.stringify(data));
          if(data.status === 'OK') {
          // show otp form
            uidStored = processedUid;
            showOtpForm();
          $('#loginFormOtpFSpan').html("0"+bg_utils.sanitize_OnlyNumbers(uid));
          } else {
            if (_.has(xhr.responseJSON, 'errorMsg')) {
                $('#loginFormMsisdnF-error').html(xhr.responseJSON.errorMsg);
            } else {
                $('#loginFormMsisdnF-error').html(bg_globals.i18n.errors.generic_error);
            }
          }
        },
        'error':    function(xhr, status, error) {
          bg_utils.log('error!');
          bg_utils.log('status: ', status);
          bg_utils.log('error: ', error);
          bg_utils.log('xhr: ', xhr);

          if (xhr.status === 401) {
            window.top.location.reload(true);
          }
          else {
            if (_.has(xhr.responseJSON, 'errorMsg')) {
              $('#loginFormMsisdnF-error').html(xhr.responseJSON.errorMsg);
            }
            else {
              $('#loginFormMsisdnF-error').html(bg_globals.i18n.errors.generic_error);
            }
          }
        },
        'complete': function() {
          uidStored = processedUid;
          uid ="";
          processedUid="";
          captchaValue="";
          bg_utils.hide('.byg_formMask');
          bg_utils.captchaReset();

        }
      });
    });


  /* submit second step login  - selfcare msisdn otp validation
  $('.loginCard__otp .loginFormSubmitBut').click(function(e) {
    $('.loginForm_err.bygForm__error').html('&nbsp;');

    //bg_utils.showPreloader('.loginForm_err.bygForm__error');

    // Check login username format

    var processedUid =  uidStored;
    var otp  =  $('#loginFormOtpF').val();

   //check is empty
    var isEmpty = false;
    var isAlphanum= false;

    isEmpty = bg_utils.isEmptyOrNull(otp,'#loginFormOtpF-error',bg_globals.i18n.form.errors.missing_field);
    isAlphanum = bg_utils.isAlphanumeric(otp);

    if ( isAlphanum !== true ){$('#loginFormOtpF-error').html(bg_globals.i18n.form.errors.invalid_otp);  return false;}
    else {$('#loginFormOtpF-error').html("");}
    if(isEmpty === true ) return false;

    bg_utils.show('.byg_formMask');
    $.ajax({
      'url':      bg_consts.API.CUSTOMER_OTPLOGIN_VALIDATEOTP,
      'type':     'POST',
      'dataType': 'json', // expected type from server
      'data':     {
        'msisdn': processedUid,
        'otp':otp,
        'timezone': new Date().getTimezoneOffset()
      },
      'success':  function(data, status, xhr) {
        bg_utils.log('success!');
        bg_utils.log('status: ', status);
        bg_utils.log('data: ', data);
        bg_utils.log('data (str): ', JSON.stringify(data));
        processedUid="";
        uidStored="";
        otp="";
        window.top.location.href = bg_globals.base_url + data.redirectUrl;
      },
      'error':    function(xhr, status, error) {
        bg_utils.log('error!');
        bg_utils.log('status: ', status);
        bg_utils.log('error: ', error);
        bg_utils.log('xhr: ', xhr);

        if (xhr.status === 401) {
          window.top.location.reload(true);
        }
        else {
          if (_.has(xhr.responseJSON, 'errorMsg')) {
                $('#loginFormOtpF-error').html(xhr.responseJSON.errorMsg);
            }
          else {
            $('#loginFormOtpF-error').html(bg_globals.i18n.errors.generic_error);
          }
        }
      },
      'complete': function() {
        otp="";
        bg_utils.hide('.byg_formMask');
      }
    });
  });


/*Resend otp
  $('.loginForm_otpResendLink').click(function(e) {

    $.ajax({
      'url': bg_consts.API.CUSTOMER_REGISTRATION_OTP_RESEND,
      'type': 'POST',
      'dataType': 'json', // expected type from server
      'data': {
        'phone': uidStored,
      },
      'success': function(data, status, xhr) {
          bg_utils.log('success!');
          bg_utils.log('status: ', status);
          bg_utils.log('data: ', data);
          bg_utils.log('data (str): ', JSON.stringify(data));

        $('#loginFormOtpF-info').html("");

        if (data.status === 'OK') {
          $('#loginFormOtpF-info').html(bg_globals.i18n.otp.resend_otp_ok);
          $('#loginFormOtpF-error').html("");
        }
        else if (data.errorMsg !== null && data.errorMsg !== "") {
          $('#loginFormOtpF-error').html(data.errorMsg);
        }
        else {
          $('#loginFormOtpF-error').html(bg_globals.i18n.errors.generic_error);
        }
      },
      'error': function(xhr, status, error) {
          bg_utils.log('error!');
          bg_utils.log('status: ', status);
          bg_utils.log('error: ', error);
          bg_utils.log('xhr: ', xhr);
        $('#loginFormOtpF-error').html(bg_globals.i18n.errors.generic_error);
      }
    })
  });
*/






});



$(document).ready(function() {
  $('.byg_logoutForm').attr('action', bg_globals.custom_logout_url == null ? bg_globals.logout_url : bg_globals.custom_logout_url);

  /* click on logout link */
  $('.naviList__logout').on('click',function() {
    $('.byg_logoutForm').submit();
  });

  /*
   $('.navbar .naviList__logout').click(function() {
   $.ajax({
   'url': bg_globals.base_url + 'perform-logout',
   'type': 'POST',
   'success': function(data, status, xhr) {
   bg_utils.log('success!');
   bg_utils.log('status: ', status);
   },
   'error': function(xhr, status, error) {
   bg_utils.log('logout error!');
   bg_utils.log('xhr:', xhr);
   bg_utils.log('status:', status);
   bg_utils.log('error:', error);
   }
   });
   });*/
});

var bg_utils = bg_utils || {};


/* errorStatusShown (internal use only)
 * Tell whether the alert has been shown or not. */

bg_utils.__errorStatusShown__ = false;


/* handleErrorStatus (internal use only)
 * Print a proper message (and log out) when ajax calls fail. */

bg_utils.__handleErrorStatus__ = function(status) {
  if (status === 401 || status === 403) {
    if (!bg_utils.__errorStatusShown__) {
      alert(bg_globals.i18n.errors.session_error);
      bg_utils.__errorStatusShown__ = true;
    }
    $('.byg_logoutForm').submit();
  }
  else {
    /* show alert with a little delay. The trick prevents useless warnings when
    switching between navigation links too quickly. */
    setTimeout(function() { alert(bg_globals.i18n.errors.generic_error); }, 3000);
  }
};


/* logger */

bg_utils.log = function() {
  if (bg_consts.DEBUG === 'true') {
    try{
      console.log.apply(console, Array.prototype.slice.call(arguments));
    }
    catch (e) {
      console.log(Array.prototype.slice.call(arguments).join('  '));
    }
  }
};


/* loadStaticContent
 * load 'what' content inside 'where' container. */

bg_utils.loadStaticContent = function(what, where) {
  $.ajax({
    'url': what,
    'type': 'GET',
    'beforeSend': function() {
      $(where).html(bg_globals.assets.preloader);
    },
    'success': function(data, status, xhr) {
      bg_utils.log('success on get', what);
      $(where).html(data);
    },
    'error': function(xhr, status, error) {
      bg_utils.log('error!', status);
      $(where).html('unable to fetch remote data');
    }
  });
};


/* preloadImages
 * preload images. Usage: preloadImages(img1, img2, ..., imgN) */

bg_utils.preloadImages = function() {
  for (var i=0; i<arguments.length; i++) {
    var img = $("<img />").attr("src", arguments[i]);
    bg_utils.log('[bg_utils::preloadImages] image "%s" preloaded', img.attr('src'));
  }
};


/* timezone
 * process 'date' with timezone taken into account. */

bg_utils.timezone = function(rawDate) {
  return '(TODO timezone) --- ' + rawDate;
};


/* apiErrorManager
 * general error manager for private API calls. */

bg_utils.apiErrorManager = function(xhr, status, err) {
  bg_utils.log('API ERROR!');
  bg_utils.log('status:', status);
  bg_utils.log('error:', err);
  bg_utils.__handleErrorStatus__(xhr.status);
};


/* backboneApiErrorManager
 * same as above, but customized for Backbone. */

bg_utils.backboneApiErrorManager = function(model, response, options) {
  bg_utils.log('BACKBONE API ERROR!');
  bg_utils.log('model:', model);
  bg_utils.log('response:', response);
  bg_utils.log('options:', options);
  bg_utils.__handleErrorStatus__(response.status);
};


/* getParameterByName
 * return the value of 'name' parameter, from GET querystring. */

bg_utils.getParameterByName = function(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

/* show|hide
 * remove or add hidden' class by Bootstrap from an element 'what'. */

bg_utils.show = function(what) { $(what).removeClass('hidden'); };
bg_utils.hide = function(what) { $(what).addClass('hidden'); };

/* same as above, but takes in input a jQuery object */

bg_utils.showEl = function(what) { what.removeClass('hidden'); };
bg_utils.hideEl = function(what) { what.addClass('hidden'); };


/* format
 * format a string. */

bg_utils.format = function() {
  var s = arguments[0];
  for (var i=0; i<arguments.length - 1; i++) {
    var reg = new RegExp("\\{" + i + "\\}", "gm");
    s = s.replace(reg, arguments[i + 1]);
  }
  return s;
};

/* showPreloader */

bg_utils.showPreloader = function(where) {
  //old version
  //$(where).html(bg_globals.assets.preloader);
  $(where).addClass('rel');
  var mask = '<div class="preloader-mask">';
  mask += bg_globals.assets.preloader_mask;
  mask += '</div>';
  $(where).append(mask);
};

/* hidePreloader */

// where: is the region element containing the preloader
// how  : removes the preloader div from the parent element provided

bg_utils.hidePreloader = function(where) {
  $(where).find('.preloader-mask').remove();
};

/* smooth scrolling to element - useful to scroll forms to alerts

where: css selector
speed:animation duration in ms
*/
bg_utils.scrollToElement=function(where, speed){
	try{
		speed = speed|500;
		$('html, body').animate({
		  scrollTop: $(where).offset().top
		}, speed);
		}catch(e){
	}
};


/* hasOperation
 * Check whether the operation 'op' is present in the global vars. */

bg_utils.hasOperation = function(op) {
  for (var i=0; i<bg_globals.operations.length; i++) {
    if (bg_globals.operations[i] === op) {
      return true;
    }
  }
  return false;
};


/*  username(mobileNumbr) format detector
  * Handles the different mobile phone number formats (intl. / local) */

bg_utils.uidProcessor   = function(uid) {
  var msisdn = uid.match(/^((\+61)|(\+610)|0)?[0-9]{9}$/);
  if (msisdn !== null) {
    res = uid.replace(/^((\+610)|(\+61)|0)/, '');
    res = '+61' + res;
    return res;
  }
  else {
    return uid;
  }
};

bg_utils.isOnlyNumber = function (field){
    var isMatch = field.match(/^[0-9]{8,10}$/)
    if (isMatch !== null) {
        return true;
    }
    return false;
}

bg_utils.isAlphanumeric = function (field){
    var phoneNumber = field.match(/^[0-9a-zA-Z]{4,10}$/)
    if (phoneNumber !== null) {
        return true;
    }
    return false;
}

bg_utils.isEmptyOrNull =function (fieldValue, placeToShowMessage, text){
    fieldValue=fieldValue.trim();
    if(fieldValue === "" || fieldValue === null )
    {
        $(placeToShowMessage).html(text);
        return true;
    }
    else
    {
        $(placeToShowMessage).html("");
    }
}

/* Date time to milliseconds converter
  ** Accepted format is dd/mm/yyyy hh:mm  */

bg_utils.dateToUTC = function (dateTime) {
  // Check format
  dateTime.match(/^[0,1][0-9]\/[0-2][0-9]\/[0-9]{4}\s(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/);
  // Split values
  var  y  = dateTime.substr(6,4);
  var  m  = dateTime.substr(3,2);
  m--;
  var  d  = dateTime.substr(0,2);
  var  h  = dateTime.substr(11,2);
  var  mm = dateTime.substr(14,2);
  // Return milliseconds
  return Date.UTC(y,m,d,h,mm);
};

bg_utils.captchaReset = function() {
    console.log('captchaReset');
    console.log(typeof grecaptchaa);
    if (typeof grecaptcha !== 'undefined' && typeof grecaptcha.reset !== 'undefined') {
        grecaptcha.reset();
    }
    $("input[name=captcha]").val('');
};

bg_utils.initCaptcha = function($input) {
    $input = $input || $("input[name=captcha]");
    if ($input.length < 1) {
        bg_utils.log("No Captcha inputs found.");
        return false;
    }
    $input.addClass("recaptchaHidden");
    var $div = $("<div class='g-recaptcha'></div>");
    $div.attr('data-sitekey', bg_globals.recaptcha_siteKey);
    $div.attr('data-callback', 'recaptchaCallback');
    $div.attr('data-expired-callback', 'recaptchaCallback');
    $input.before($div);
};
bg_utils.initCaptchaInViews = function($input) {

  bg_utils.initCaptcha($input);

	  $.getScript('https://www.google.com/recaptcha/api.js?hl='+bg_globals.language, function() {});

	};

var recaptchaCallback = function() {
    console.log('recaptchaCallback');
    if (typeof grecaptcha !== 'undefined') {
        var response = grecaptcha.getResponse(),
            $input   = $("input[name=captcha]");
        console.log('response,$input');
        console.log(response,$input);
        $input.length && $input.val(response || '') && $input.keyup();
    }
};


/** 
 * returns input numbers or empty string if str contains other chars
*/
 bg_utils.sanitize_OnlyNumbers = function(str){
  try{
    var regex1 = RegExp('^[0-9]+$','ig');
    var Nstr = regex1.exec(str)[0];
    var temp = document.createElement('div');
    temp.textContent = Nstr;
    return temp.innerHTML;
   }catch(e){
     return ""; 
   }  
};

bg_utils.removeFirstZeroByMsisdn = function(msisdn) {
    if (msisdn !== null) {
        msisdn = msisdn.trim(msisdn);
        msisdn = msisdn.replace(/^0/, '');
    }
    return msisdn;
}


var bg_validator = bg_validator || {};

/* validate
 * setup jquery validator and bind it to an element. Parameters:
 *
 * what: jquery selector to bind validator to
 * apiUrl: api endpoint for form submission (if valid)
 * messages: custom i18n messages
 * rules: how to walidate fields
 * alertBox: where to put alert message (jquery selector)
 * successFnc: callback fired when submission is ok */

bg_validator.validate = function(what, apiUrl, messages, rules, alertBox, successFnc) {

  /* TODO: move rules and override outside function */
  /* custom rule for pattern matching */

  jQuery.validator.addMethod('pattern', function(value, element, param) {
    return this.optional(element) || param.test(value);
  });


// Method for AU Mobile Phone Validation, if format is wrong, returns null

  jQuery.validator.addMethod('au_phonePattern', function(value, element, param) {
    return this.optional(element) || bg_utils.uidProcessor(value) !== null;
  });

  /* override default messages */

  jQuery.extend(jQuery.validator.messages, {
    'required': bg_globals.i18n.form.errors.missing_field,
    'email': bg_globals.i18n.form.errors.wrong_email
  });

  return $(what).validate({
    'messages'    : messages,
    'rules'       : rules,
	  'errorElement':'span',
	  'errorClass'  :'bygForm__error',
    'submitHandler': function(form) {

      if (typeof apiUrl === 'undefined')
        return;

      /* hide and clean up error box and show form mask with preloader */

      bg_utils.hide(alertBox);
      $(alertBox).find('.byg_msg').html();
      bg_utils.show('.byg_formMask');

      $.ajax({
        'url'     : apiUrl,
        'type'    : 'POST',
        'dataType': 'json', // expected type from server
        'data'    : $(form).serialize(),
        'success' : function(data, status, xhr) {

          bg_utils.log('xhr success!');
          bg_utils.log('status: ', status);
          bg_utils.log('data: ', data);

          if (data.status === 'OK') {
            successFnc();
          }
          else {
            // TODO - use bg_validator.printErrors (below)
            bg_utils.log('nope, found some errors!');
            if (data.errorMsg !== undefined) {
              bg_utils.show(alertBox);
              $(alertBox).find('.byg_msg').html(data.errorMsg);
            }
            if (data.fields !== undefined) {
              var errors = {};
              for (var i=0; i<data.fields.length; i++) {
                bg_utils.log(data.fields[i].errorMsg.join(', '));
                errors[data.fields[i].fieldName] = data.fields[i].errorMsg.join(', ');
              }
              validator.showErrors(errors);
            }
          }
        },
        'error': function(xhr, status, error) {
          // erorr managment here\
          bg_utils.apiErrorManager(xhr, status, error);
        },
        'complete': function() {
          bg_utils.hide('.byg_formMask');
        }
      });
    }
  });
};

/* validateV2
 * setup jquery validator and bind it to an element. Parameters:
 *
 * what                : jquery selector to bind validator to
 * apiUrl              : api endpoint for form submission (if valid)
 * messages            : custom i18n messages
 * rules               : how to walidate fields
 * alertBox            : where to put alert message (jquery selector)
 * successFnc          : callback fired when submission is ok
 *
 * extraOb             : extra params
 * Function beforeSend : called before the actual submit. will be passed a reference to the form,
 * and an obj with a copy of the validate
 * function arguments. If it returns false, the submit will not happen.
 */

bg_validator.validateV2 = function(what, apiUrl, messages, rules, alertBox, successFnc,extraOb) {

  var paramsOb = {
    what      :what,
    apiUrl    :apiUrl,
    messages  :messages,
    rules     :rules,
    alertBox  :alertBox,
    successFnc: successFnc,
    extraOb   :extraOb
  };

  /* TODO: move rules and override outside function */
  /* custom rule for pattern matching */

  jQuery.validator.addMethod('pattern', function(value, element, param) {
    return this.optional(element) || param.test(value);
  });

  /* override default messages */

  jQuery.extend(jQuery.validator.messages, {
    'required': bg_globals.i18n.form.errors.missing_field,
    'email'   : bg_globals.i18n.form.errors.wrong_email
  });

  bg_validator.addMethods();

  return $(paramsOb.what).validate({
    'messages'     : paramsOb.messages,
    'rules'        : paramsOb.rules,
	  'errorElement' :'span',
	  'errorClass'   :'bygForm__error',
    'submitHandler': function(form) {
       /* copy of the validator arguments*/
      var paramsObCp= _.clone(paramsOb);
      if(paramsObCp.extraOb && typeof paramsObCp.extraOb.beforeSend==='function'){
        if (paramsObCp.extraOb.beforeSend(form,paramsObCp) === false){
          return;
        }
      }

      if (typeof paramsObCp.apiUrl === 'undefined')
        return;

      /* hide and clean up error box and show form mask with preloader */

      bg_utils.hide(paramsObCp.alertBox);
      $(paramsObCp.alertBox).find('.byg_msg').html();
      bg_utils.show('.byg_formMask');

      $.ajax({
        'url'     : paramsObCp.apiUrl,
        'type'    : 'POST',
        'dataType': 'json', // expected type from server
        'data'    : $(form).serialize(),
        'success' : function(data, status, xhr) {

          bg_utils.log('xhr success!');
          bg_utils.log('status: ', status);
          bg_utils.log('data: ', data);

          if (data.status === 'OK') {
            paramsObCp.successFnc(paramsObCp);
          }
          else {
            // TODO - use bg_validator.printErrors (below)
            bg_utils.log('nope, found some errors!');
            if (data.errorMsg !== undefined) {
              bg_utils.show(paramsObCp.alertBox);
              $(paramsObCp.alertBox).find('.byg_msg').html(data.errorMsg);
            }
            if (data.fields !== undefined) {
              var errors = {};
              for (var i=0; i<data.fields.length; i++) {
                bg_utils.log(data.fields[i].errorMsg.join(', '));
                errors[data.fields[i].fieldName] = data.fields[i].errorMsg.join(', ');
              }
              validator.showErrors(errors);
            }
          }
        },
        'error': function(xhr, status, error) {
          // erorr managment here\
          bg_utils.apiErrorManager(xhr, status, error);
        },
        'complete': function() {
          bg_utils.hide('.byg_formMask');
        }
      });
    }
  });
};

bg_validator.addMethods = function(){
/* validator for australian phone */
 jQuery.validator.addMethod('phone_au', function(value, element, param) {
    return this.optional( element ) || /^0[0-9]{9}$/.test( value );
  }, bg_globals.i18n.form.errors.phone_customer);

  jQuery.validator.addMethod('phone_au_multiformat', function(value, element, param) {
     return this.optional( element ) || /^((\+61)|(\+610)|0)?[0-9]{9}$/.test( value );
   }, bg_globals.i18n.form.errors.phone_customer);

};

/* unbind
 * remove validator from 'what' (jquery selector). */

bg_validator.unbind = function(what) {
  $(what).removeData('validator');
};


/* printErrors
 * print all errors from 'data', which is the error object returned from the
 * server. */

bg_validator.printErrors = function(validator, data, alertBox) {
  bg_utils.log(alertBox);
  if (data.errorMsg !== undefined && alertBox !== undefined) {
    bg_utils.show(alertBox);
    $(alertBox).find('.byg_msg').html(data.errorMsg);
  }
  if (data.fields !== undefined) {
    var errors = {};
    for (var i=0; i<data.fields.length; i++) {
      errors[data.fields[i].fieldName] = data.fields[i].errorMsg.join(', ');
    }
    validator.showErrors(errors);
  }
};
